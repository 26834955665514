import Navbar from '../../components/Navbar/Navbar';
import { re,userDate,clearCookiesAndSession, CookieMonster, is_mobile } from '../../utilities/utilities';
import { SynxisReservation } from '../../components/SynxisReservation/SynxisReservation';
import { PackReservation } from '../../components/SynxisReservation/PackReservation';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CancelBooking } from './CancelBooking';
import Handler from '../../components/Handler/Handler';
import { lt_actions } from '../../utilities/actions';
import React, { useState } from 'react';




const Booking = ({booking,handleModifyBooking,handleCancelBooking}) => {
    
    const [cancelPop, fireCancelPop] = useState("");
    function kill() {
        fireCancelPop("");
    }

    let navigate = useNavigate();
    function handleModify() {
        navigate("/");
    }
    let { success } = useParams();

    if(!booking){
        if(success === undefined){re("/");return;}
        if(success === "success"){re("/");return;}
        if(success === "found"){re("/find-booking");return;}
        re("/")
    }
    let resJSON = booking.data.reservation;
    let preReservation;
    
    if(booking.type==="create"){
        preReservation = JSON.parse(resJSON);
    }else if(booking.type==="update"){
        preReservation = JSON.parse(resJSON);
    }
    let reservation = PackReservation(preReservation);

    let header;
    let sub;
    console.log("RESERVATION",reservation);
    if(reservation.status != "Cancel"){
        if(booking.data.status===200){
            // NEW BOOKING
            header="Room Successfully Booked!";
            sub=`Thank you for choosing Las Terrazas Resort and Residences ${reservation.name}.`
        }else if(booking.data.status===201){
            // FIND BOOKING
            header="Your reservation details"
            sub=`Hi ${reservation.name}, here are your reservation details.`
        }else if(booking.data.status===202){
            // FIND BOOKING
            header="Your reservation was modified successfully"
            sub=`Hi ${reservation.name}, here are your new reservation details.`
        }
    }else{
            header="Cancelled Reservation"
            sub=`This reservation was cancelled on ${userDate(preReservation["@attributes"].LastModifyDateTime)}.`
    }
    
    const monster = new CookieMonster();
    monster.eatAllCookies();

  return (
    <>
    {cancelPop}
    {clearCookiesAndSession()}
    <div id="home" className='container no-pad'>
      <div className='flex no-gap col-body'>
        <div className='container br-10vh'>
          <div id="head">
            <Navbar />
          </div>
        
            <div className='pad-y-2'>
                <div className='flex flex-y-center flex-walls flex-break'>
                    <div>
                        <h1 className='br-10'>{header}</h1>
                        <p>{sub}</p>
                    </div>
                    <div className='flex flex-right modify-buttons'>
                        {booking.data.modify===true?
                            <button className='btn-action' onClick={()=>{ handleModifyBooking(preReservation);handleModify(); }}>Modify Reservation</button>
                        :""}
                        {booking.data.cancel===true?
                            
                            <button className='btn-action' onClick={()=>{ 
                                let cancelArgs = {confirmationNumber:reservation.confirmationNumber}
                                let event = lt_actions[1001];
                                event['help']=<CancelBooking yes={handleCancelBooking} no={kill} yesArgs={cancelArgs}/>;
                                event['kill']=kill;
                                event['title']="Cancel Reservation";
                                fireCancelPop(<Handler event={event}/>);
                            }}>Cancel Reservation</button>
                        :""}
                    </div>
                </div>
                <SynxisReservation 
                    confirmationNumber={reservation.confirmationNumber}
                    itineraryNumber={reservation.itineraryNumber}
                    start={reservation.start}
                    end={reservation.end}
                    totalDays={reservation.totalDays}
                    adult={reservation.guests.adult}
                    child={reservation.guests.child }
                    room={reservation.room}
                    roomDetails={reservation.roomDetails}
                    name={reservation.name}
                    lastname={reservation.lastname}
                    email={reservation.email}
                    address={reservation.address}
                    city={reservation.city}
                    state={reservation.state}
                    country={reservation.country}
                    zip={reservation.zip}
                    ratePlanName={reservation.ratePlanName}
                    rateDetails={reservation.rateDetails}
                    daily={reservation.daily}
                    totalCostWithTax={reservation.totalCostWithTax}
                    policies={reservation.policies}
                    cancelpolicy={reservation.cancelpolicy}
                    deposit={reservation.deposit}
                    today={reservation.today}
                    CC={reservation.CC}
                    comments={reservation.comments}
                />
            </div>
          
        </div>
        {!is_mobile()?
        <div className='hero-container'>
            <video className="hero-shot" mute="true" autoPlay={true} loop="true">
                <source src="/images/test.mp4" type="video/mp4" />
            </video>
        </div>
        :""}
      </div>
    </div>

    </>
  )
}

export default Booking;
