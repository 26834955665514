import React from 'react'

export const SynxisReservation = ({
    confirmationNumber,
    itineraryNumber,
    start,
    end,
    totalDays,
    adult,
    child,
    room,
    roomDetails,
    name,
    lastname,
    email,
    address,
    city,
    state,
    country,
    zip,
    ratePlanName,
    rateDetails,
    daily,
    totalCostWithTax,
    policies,
    cancelpolicy,
    deposit,
    today,
    CC,
    comments
    }) => {
  return (
      <div className='res-details'>
        <section>
                        <div className='flex spec'>
                            <label>Reservation Number:</label>
                            <div>{confirmationNumber}</div>
                        </div>
                        <div className='flex spec'>
                            <label>Room Name:</label>
                            <div>{room}</div>
                        </div>
                <div className='flex flex-even flex-resp-wrap'>
                    <div className=''>
                        <div className='flex spec'>
                            <label>Rate Name:</label>
                            <div>{ratePlanName}</div>
                        </div>
                        <div className='flex spec'>
                            <label>Itinerary Number:</label>
                            <div>{itineraryNumber}</div>
                        </div>
                        <div className='flex spec'>
                            <label>Length of stay:</label>
                            <div>{totalDays} night(s)</div>
                        </div>
                        <div className='flex spec'>
                            <label>Arrival Date:</label>
                            <div>{start}</div>
                        </div>
                        <div className='flex spec'>
                            <label>Departure Date:</label>
                            <div>{end}</div>
                        </div>
                        
                    </div>


                    <div className=''>
                        <div className='flex spec'>
                            <div className='flex '>
                                <label>Adults:</label>
                                <div>{adult}</div>
                            </div>
                            <div className='flex '>
                                <label>Children:</label>
                                <div>{child}</div>
                            </div>
                        </div>
                        <div className='flex spec'>
                            <label>Night Rate:</label>
                            <div>{daily}</div>
                        </div>
                        <div className='flex spec'>
                            <label>Total Cost with Taxes:</label>
                            <div>{totalCostWithTax}</div>
                        </div>
                        <div className='flex spec'>
                            <label>Deposit:</label>
                            <div>{deposit} </div> 
                            <div>Paid on: {today}</div>
                        </div>
                        <div className='flex spec'>
                            <label>Card Number:</label>
                            <div>XXXX XXXX XXXX {CC}</div>
                        </div>
                    </div>
                </div> 
                                
            </section>


            <section>
                <h4 className=''>Guest Information</h4>    
                <div className=''>
                    <div className='flex spec'>
                        <label>Full Name:</label>
                        <div>{name} {lastname}</div>
                    </div>
                    <div className='flex spec'>
                        <label>Email:</label>
                        <div>{email}</div>
                    </div>
                    <div className='flex spec'>
                        <label>Address:</label>
                        <div>{address} {city} , {state}, {zip}, {country} </div>
                    </div>
                    <div className='spec'>
                        <label>Additional Details and Preferences:</label>
                        <div>{comments}</div>
                    </div>
                </div>
                
            </section>

            <section>
                <h4 className='br-20'>Room and Rate Description</h4>    
                <div className='flex flex-even flex-resp-wrap'>
                    <div>
                        <div className='flex'>
                            <label>{ratePlanName}</label>
                        </div>
                        <div className='flex spec'>
                            <div>{rateDetails}</div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <label>{room}:</label>
                        </div>
                        <div className='spec'>
                            <div>{roomDetails}</div>
                        </div>                    
                    </div>
                </div>
                
            </section>

            <section>
                <h4 className=''>Policies</h4>    
                <div className=''>
                    <div className=''>
                        <label>Payment</label>
                        <div>{policies}</div>
                        <br/>
                        <label>Cancellation</label>
                        <div>{cancelpolicy}</div>
                    </div>
                                     
                </div>
            </section>
      </div>
  )
}
